<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
    :class="color"
  >
    <line class="a" y2="6" transform="translate(10 18)" />
    <g class="b" transform="translate(0 12)">
      <rect class="d" width="20" height="6" rx="3" />
      <rect class="e" x="1" y="1" width="18" height="4" rx="2" />
    </g>
    <g class="c" transform="translate(-479.342 -680.896)">
      <path class="d" d="M494.7,694.9H483.987l-3.646-14h18Z" />
      <path
        class="f"
        d="M 493.1502380371094 692.8958129882812 L 495.754150390625 682.8958129882812 L 482.9291076660156 682.8958129882812 L 485.5331726074219 692.8958129882812 L 493.1502380371094 692.8958129882812 M 494.6961364746094 694.8958129882812 L 483.9872741699219 694.8958129882812 L 480.3416137695312 680.8958129882812 L 498.3416442871094 680.8958129882812 L 494.6961364746094 694.8958129882812 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Rounds",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a,
  .b {
    stroke: $midnight;
    stroke-width: 2px;
  }
  .f {
    fill: $midnight;
  }
}

.a {
  fill: #fff;
}
.a,
.b {
  stroke: $strom;
  stroke-width: 2px;
}
.a,
.b,
.c {
  stroke-miterlimit: 10;
}
.b,
.c,
.e {
  fill: none;
}
.d,
.f {
  stroke: none;
}
.f {
  fill: $strom;
}
</style>