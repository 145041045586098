<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    :class="color"
  >
    <g class="a">
      <rect class="b" width="20" height="22" rx="3" />
      <rect class="c" x="1" y="1" width="18" height="20" rx="2" />
    </g>
    <line class="a" y2="10" transform="translate(5 8) rotate(-90)" />
    <line class="a" y2="10" transform="translate(5 14) rotate(-90)" />
    <path
      class="a"
      d="M0,10.167"
      transform="translate(4.917 14.667) rotate(-90)"
    />
  </svg>
</template>

<script>
export default {
  name: "Details",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a {
    stroke: $midnight;
    stroke-width: 2px;
  }
}

.a,
.c {
  fill: none;
}
.a {
  stroke: $strom;
  stroke-width: 2px;
}
.b {
  stroke: none;
}
</style>