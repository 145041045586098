<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="color"
  >
    <g class="a" transform="translate(5)">
      <circle class="c" cx="7" cy="7" r="7" />
      <circle class="d" cx="7" cy="7" r="6" />
    </g>
    <g class="b" transform="translate(0 12)">
      <path
        class="c"
        d="M23.974,12H.026C.009,11.747,0,11.5,0,11.25A10.835,10.835,0,0,1,3.515,3.3,12.336,12.336,0,0,1,12,0a12.336,12.336,0,0,1,8.485,3.3A10.835,10.835,0,0,1,24,11.25c0,.249-.009.5-.026.749Z"
      />
      <path
        class="e"
        d="M 21.90975570678711 10 C 21.62238502502441 8.025416374206543 20.65594291687012 6.196591854095459 19.1173095703125 4.754129886627197 C 17.22286033630371 2.978100061416626 14.69521045684814 2 12 2 C 9.304789543151855 2 6.777140140533447 2.978100061416626 4.882699966430664 4.754129886627197 C 3.344059467315674 6.196591854095459 2.377615451812744 8.025416374206543 2.090244054794312 10 L 21.90975570678711 10 M 23.97369003295898 12 L 23.97289085388184 12 L 0.02630000002682209 12 C 0.008559999987483025 11.74736022949219 -1.77635683940025e-15 11.49528980255127 -1.77635683940025e-15 11.25008964538574 C -1.77635683940025e-15 8.245089530944824 1.248260021209717 5.419939994812012 3.514820098876953 3.295049905776978 C 5.781350135803223 1.170199990272522 8.794779777526855 -8.881784197001252e-16 12 -8.881784197001252e-16 C 15.20522022247314 -8.881784197001252e-16 18.21865081787109 1.170199990272522 20.48517990112305 3.295049905776978 C 22.75173950195312 5.419939994812012 24 8.245089530944824 24 11.25008964538574 C 24 11.49905967712402 23.99115943908691 11.75113010406494 23.97373962402344 11.99930000305176 L 23.97369003295898 12 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Players",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a {
    stroke: $midnight;
    stroke-width: 2px;
  }
  .e {
    fill: $midnight;
  }
}
.a,
.b,
.d {
  fill: none;
}
.a {
  stroke: $strom;
  stroke-width: 2px;
}
.a,
.b {
  stroke-miterlimit: 10;
}
.c,
.e {
  stroke: none;
}
.e {
  fill: $strom;
}
</style>