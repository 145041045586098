<template>
  <div v-if="loaded">
    <div class="breadcrumbs">
      <div>
        <p class="tour-name">
          <router-link
            :to="{
              name: 'manage',
            }"
            >Dashboard</router-link
          >
        </p>

        <template v-if="!oneOffEvent">
          <p class="divider">/</p>
          <p class="tour-name">
            <router-link
              :to="{
                name: 'tour-overview',
                params: { tourId: managedEvent.tour.id },
              }"
              >Tour overview</router-link
            >
          </p>
        </template>
        <p class="divider">/</p>
        <p class="tail" v-if="$route.name == 'event-overview'">
          Event timeline
        </p>
        <p class="tail" v-if="$route.name == 'event-rounds'">Event rounds</p>
        <p class="tail" v-if="$route.name == 'event-groups-round'">
          Group editor
        </p>
        <p class="tail" v-if="$route.name == 'event-players'">Event players</p>
        <p class="tail" v-if="$route.name == 'event-edit-results'">
          Event results
        </p>
        <p class="tail" v-if="$route.name == 'event-contact'">Event details</p>
        <p class="tail" v-if="$route.name == 'event-settings'">
          Event settings
        </p>
        <p class="tail" v-if="$route.name == 'event-messages'">
          Event messages
        </p>
        <p class="tail" v-if="$route.name == 'one-off-event-settings'">
          Event settings
        </p>
        <p class="tail" v-if="$route.name == 'one-off-settings-add-event'">
          Add event
        </p>
      </div>
    </div>
    <div class="wrapper-margin">
      <ManagerEventMenu :event="managedEvent" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import ManagerEventMenu from "@/components/ManagerEventMenu";

export default {
  name: "EventManager",
  data() {
    return {};
  },
  components: { ManagerEventMenu },
  computed: {
    oneOffEvent() {
      return this.managedEvent.tour.events.length == 1 ? true : false;
    },
    loaded() {
      return this.$store.getters.managedEvent.loaded;
    },
    managedEvent() {
      return this.$store.getters.managedEvent.data;
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch("loadManagedEventData", this.$route.params.eventId);
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.wrapper-margin {
  width: 100%;
}
.breadcrumbs {
  margin: 24px 0 24px 12px;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  div {
    display: flex;
    width: calc(100% - 20px);
    a {
      color: $ocean;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;

      &.divider {
        min-width: 12px;
        text-align: center;
      }

      &.tail {
        min-width: 46px;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .wrapper-margin {
    margin-bottom: 0px;
  }
  .breadcrumbs {
    padding-bottom: 40px;
    border-right: 1px solid $sleet;
    width: 75%;
    margin: 0;
    padding-left: 12px;
    display: flex;
    align-items: center;
    div {
      a {
        color: $ocean;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
</style>

