<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="color"
  >
    <path
      class="a"
      d="M3,1V14a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V6a4,4,0,0,1,4-4h0a4,4,0,0,1,4,4V19"
      transform="translate(1 2)"
    />
    <g class="b" transform="translate(16 24) rotate(-90)">
      <circle class="c" cx="4" cy="4" r="4" />
      <circle class="d" cx="4" cy="4" r="3" />
    </g>
    <g class="b" transform="translate(0 8) rotate(-90)">
      <circle class="c" cx="4" cy="4" r="4" />
      <circle class="d" cx="4" cy="4" r="3" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Timeline",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a,
  .b {
    stroke: $midnight;
    stroke-width: 2px;
  }
}
.a,
.d {
  fill: none;
}
.a,
.b {
  transition: all ease 0.3s;
  stroke: $strom;
  stroke-width: 2px;
}
.a {
  stroke-miterlimit: 10;
}
.b {
  fill: #fff;
}
.c {
  stroke: none;
}
</style>