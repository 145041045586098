<template>
  <div class="mobile-event-menu">
    <div class="header">
      <h3>{{ event.name }}</h3>
      <div class="public-link d-xl-none mb-4">
        <span @click="copyLink()"><CopyLink /></span>

        <p>
          <router-link :to="{ name: 'public-event-results' }"
            >Public page</router-link
          >
        </p>
      </div>
      <div class="status-row">
        <template v-if="oneOffEvent">
          <div class="status" v-if="event.tour.isPublished">
            <On />Published
          </div>
          <div class="status" v-else><Off />Unpublished</div>
        </template>

        <template v-if="event.status == 'COMPLETED'">
          <div class="status"><On color="midnight" />Finished</div>
        </template>
        <template v-else-if="showRegOpeningDate">
          <div class="status">
            <RegistrationOpen color="sunrise" />
            Opens
            {{ regOpenDateFormater(event.registrationOpenAt) }}
          </div>
        </template>
        <template v-else>
          <div class="status" v-if="event.isRegistrationOpen">
            <RegistrationOpen />Open
          </div>
          <div class="status" v-else><Off />Closed</div>
          <div class="status">
            <On color="ocean" /> {{ event.playersCount }} Confirmed
          </div>
          <div class="status">
            <WaitingList />{{ waitingListLength }} Waiting
          </div>
        </template>
      </div>
    </div>
    <div class="desktop-right-column">
      <div class="event-date">
        <span><CalendarIcon /></span>

        <p>{{ eventDate }}</p>
      </div>
      <div class="rounds-pools">
        <span> <Rounds class="midnight" /></span>
        <p>
          {{ event.rounds.length }}
          {{ event.rounds.length == 1 ? "Round" : "Rounds" }}
          <template v-if="event.rounds[0].pools.length > 1">
            ({{ event.rounds[0].pools.length }} Pools)
          </template>
        </p>
        <div class="event-type" v-if="event.pdgaType">
          {{ event.pdgaType.name }}
        </div>
      </div>
      <div class="public-link">
        <span @click="copyLink()"><CopyLink /></span>

        <p>
          <router-link :to="{ name: 'public-event-results' }"
            >Public page</router-link
          >
        </p>
      </div>
    </div>
    <nav>
      <div class="nav-spacer"></div>
      <router-link class="menu-item" :to="{ name: 'event-overview' }">
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-overview' }"
        >
          <div class="icon">
            <Timeline
              class="timeline-icon"
              :color="{ midnight: $route.name == 'event-overview' }"
            />
          </div>
          <div class="text">
            <p>Timeline</p>
          </div>
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'event-rounds' }">
        <div
          class="item-container"
          :class="{
            active:
              $route.name == 'event-rounds' ||
              $route.name == 'event-groups-round',
          }"
        >
          <div class="icon">
            <Rounds
              class="rounds-icon"
              :color="{
                midnight:
                  $route.name == 'event-rounds' ||
                  $route.name == 'event-groups-round',
              }"
            />
          </div>
          <div class="text">
            <p>Rounds</p>
          </div>
        </div>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'event-players' }">
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-players' }"
        >
          <div class="icon">
            <Players
              class="players-icon"
              :color="{ midnight: $route.name == 'event-players' }"
            />
          </div>
          <div class="text">
            <p>Players</p>
          </div>
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'event-edit-results' }">
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-edit-results' }"
        >
          <div class="icon">
            <Results
              class="results-icon"
              :color="{ midnight: $route.name == 'event-edit-results' }"
            />
          </div>
          <div class="text">
            <p>Results</p>
          </div>
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'event-contact' }">
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-contact' }"
        >
          <div class="icon">
            <Details
              class="details-icon"
              :color="{ midnight: $route.name == 'event-contact' }"
            />
          </div>
          <div class="text">
            <p>Details</p>
          </div>
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'event-messages' }">
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-messages' }"
        >
          <div class="icon">
            <Messages
              class="message-icon"
              :color="{ strom: $route.name !== 'event-messages' }"
            />
          </div>
          <div class="text">
            <p>Messages</p>
          </div>
        </div>
      </router-link>
      <router-link
        class="menu-item"
        :to="{ name: 'one-off-event-settings' }"
        v-if="oneOffEvent"
      >
        <div
          class="item-container"
          :class="{ active: $route.name == 'one-off-event-settings' }"
        >
          <div class="icon">
            <Settings
              :color="{ strom: $route.name != 'one-off-event-settings' }"
              class="settings settings-icon"
            />
          </div>
          <div class="text">
            <p>Settings</p>
          </div>
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'event-settings' }" v-else>
        <div
          class="item-container"
          :class="{ active: $route.name == 'event-settings' }"
        >
          <div class="icon">
            <Settings
              :color="{ strom: $route.name != 'event-settings' }"
              class="settings settings-icon"
            />
          </div>
          <div class="text">
            <p>Settings</p>
          </div>
        </div>
      </router-link>
      <div class="nav-spacer"></div>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
import Timeline from "@/assets/icons/Timeline";
import Rounds from "@/assets/icons/Rounds";
import Players from "@/assets/icons/Players";
import Results from "@/assets/icons/Results";
import Details from "@/assets/icons/Details";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import WaitingList from "@/assets/icons/WaitingList";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import CopyLink from "@/assets/icons/CopyLink";
import Settings from "@/assets/icons/Settings";
import Messages from "@/assets/icons/Messages";

export default {
  name: "ManagerEventMenu",
  props: ["event"],
  data() {
    return {};
  },
  components: {
    Timeline,
    Rounds,
    Players,
    Results,
    Details,
    On,
    CalendarIcon,
    WaitingList,
    RegistrationOpen,
    Off,
    CopyLink,
    Settings,
    Messages,
  },
  computed: {
    oneOffEvent() {
      return this.event.tour.events.length == 1 ? true : false;
    },
    eventDate() {
      if (moment(this.event.startDate).isSame(this.event.endDate, "day")) {
        return moment(this.event.startDate).format("dddd D MMMM");
      } else {
        return `${moment(this.event.startDate).format("ddd DD MMM")} - ${moment(
          this.event.endDate
        ).format("ddd DD MMM")}`;
      }
    },
    showRegOpeningDate() {
      if (this.event.isRegistrationOpen) return false;
      if (moment(this.event.registrationOpenAt).isAfter()) return true;
      return false;
    },
    waitingListLength() {
      return this.event.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
  },
  methods: {
    copyLink() {
      let envURL = "";

      if (process.env.NODE_ENV === "production") {
        envURL = "https://www.tjing.se/event";
      } else if (process.env.NODE_ENV === "test") {
        envURL = "https://test.tjing.se/event";
      } else {
        envURL = "https://dev.tjing.se/event";
      }
      navigator.clipboard.writeText(`${envURL}/${this.event.id}/rounds`);

      this.$store.dispatch("showMessage", {
        show: true,
        message: "URL copied",
        type: "success",
      });
    },
    regOpenDateFormater(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
  },
  mounted() {
    this.$nextTick(() => {
      const activeElement =
        document.getElementsByClassName("active")[0].parentNode;
      activeElement.parentNode.scrollLeft = activeElement.offsetLeft;
    });
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.desktop-right-column {
  display: none;
}
.status-row {
  display: none;
}
.mobile-event-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.header {
  width: 100%;
  padding: 0 12px;
  h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
nav {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  min-width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 0px 12px 0px;
  margin-bottom: 24px;
  scroll-snap-type: x proximity;
  scroll-padding: 0 12px;

  ::-webkit-scrollbar {
    display: none;
  }

  .nav-spacer {
    min-width: 12px;
    display: block;
    height: 100%;
    scroll-snap-align: start;
  }
}

.menu-item {
  width: 18%;
  min-width: 18%;
  max-width: 100px;
  position: relative;
  display: flex;
  margin: 2px 4% 2px 0;
  scroll-snap-align: start;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:last-of-type {
    margin-right: 0px;
  }
}
.item-container {
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 0 1px $sleet;
  transition: all ease 0.3s;
  padding: 15% 0px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px $sleet;
  }

  .text {
    p {
      font-size: 3vw;
    }
  }

  .icon {
    svg {
      height: 5.5vw;
      width: auto;
    }
    .message-icon {
      height: 5vw;
    }
    .settings-icon {
      height: 6vw;
    }
    .message-icon {
      height: 5vw;
    }
    .settings-icon {
      height: 6vw;
    }
  }

  &.active {
    box-shadow: 0 0 0 2px $ocean;
    p {
      color: $midnight;
    }
  }

  p {
    transition: all ease 0.3s;
    margin: 0;
    font-size: 16px;
    @include Gilroy-Bold;
    color: $strom;
  }
}
.public-link {
  display: flex;
  margin-bottom: 0px;
  cursor: pointer;
  svg {
    margin-right: 8px;
    width: 12px;
    height: 18px;
  }

  a:hover {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    margin: 0;
    color: $ocean;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
  nav {
    justify-content: flex-start;
  }
  .menu-item {
    max-width: 100px;
    min-width: 100px;
    margin-right: 24px;

    .item-container {
      .icon {
        svg {
          height: 32px;
        }
      }
      .text {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .public-link {
    cursor: pointer;
    svg {
      width: 18px;
      height: 24px;
      margin-right: 0;
    }
    span {
      display: flex;
      justify-content: center;
      width: 30px;
      margin-right: 8px;
    }

    a:hover {
      text-decoration: underline;
    }
    p {
      margin: 0;
      color: $ocean;
    }
  }
  .mobile-event-menu {
    justify-content: flex-start;
  }
  .desktop-right-column {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .tags {
      display: flex;
      div {
        margin-right: 12px;
      }
    }

    .event-date {
      display: flex;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-right: 8px;
      }
    }
    .rounds-pools {
      display: flex;
      align-items: center;
      span {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-right: 8px;
      }
    }

    > div {
      display: flex;
      margin-top: 20px;
      button {
        margin-left: 16px;
      }
      p {
        margin: 0;
        font-size: 18px;
      }
    }

    .tour-type {
      color: $sunset;
      background: #fff0ec;
      border: 1px solid #ffc5b5;
      display: inline-flex;
      padding: 5px 10px;
      border-radius: 6px;
      @include Gilroy-Bold;
      font-size: 14px;
    }
    .event-type {
      margin-left: 16px;
      color: $ocean;
      background: #e5f8f8;
      border: 1px solid #9be1e4;
      display: inline-flex;
      padding: 5px 10px;
      border-radius: 6px;
      @include Gilroy-Bold;
      font-size: 14px;
    }
  }
  nav {
    padding: 0;
    margin-top: 48px;
    box-shadow: inset 0px 11px 1px -10px $sleet,
      inset 0px -11px 1px -10px $sleet;
    margin-bottom: 64px;
    overflow: unset;
    max-width: 100%;
    justify-content: space-between;

    .menu-item {
      min-width: unset;
      max-width: unset;
      margin: 0;
      scroll-snap-align: start;
      width: auto;

      &::after {
        display: none;
      }

      .item-container {
        position: relative;
      }
    }

    .nav-spacer {
      display: none;
    }
  }
  .header {
    width: 75%;
    border-right: 1px solid $sleet;
    padding-top: 30px;
    h3 {
      font-size: 32px;
      margin: 0;
    }

    .status-row {
      margin-top: 32px;
      display: flex;

      .status {
        margin-right: 30px;
        display: flex;
        align-items: center;
        font-size: 18px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .item-container {
    background: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 24px 12px;
    height: auto;
    border-bottom: 4px solid transparent;
    transition: all ease 0.3s;

    &:hover {
      box-shadow: none;
      border-bottom: 4px solid $strom;
    }

    &.active {
      box-shadow: none;
      border-bottom: 4px solid $midnight;
    }
    .icon {
      margin-right: 12px;

      .message-icon {
        height: 20px !important;
      }
      .settings-icon {
        height: 26px !important;
      }
      .options-icon {
        width: 22px;
        height: 22px;
      }
      .message-icon {
        height: 20px !important;
      }
      .settings-icon {
        height: 26px !important;
      }

      svg {
        height: 24px !important;
        width: unset;
      }
    }

    .text {
      p {
        font-size: 18px;
      }
    }
  }
}
</style>
