<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="20"
    viewBox="0 0 26 20"
    :class="color"
  >
    <g id="Messages" transform="translate(1 1)">
      <rect
        id="Rectangle_1456"
        data-name="Rectangle 1456"
        width="24"
        height="18"
        rx="3"
        fill="none"
        stroke="#2d323c"
        stroke-width="2"
      />
      <path
        id="Path_2101"
        data-name="Path 2101"
        d="M1.207,2.81l9.072,7.228a3.081,3.081,0,0,0,3.848-.01l9.08-7.32"
        transform="translate(-0.207 -0.708)"
        fill="none"
        stroke="#2d323c"
        stroke-linecap="square"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Messages",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
rect,
path {
  transition: all ease 0.3s;
}
.strom {
  rect {
    stroke: $strom;
  }
  path {
    stroke: $strom;
  }
}
</style>